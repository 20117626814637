export default {
  fieldsState: {
    fname_512: "",
    lname_513: "",
    email_516: "",
    ind_telemovel_511: "+351",
    telemovel_511_numero: "",
    easygoi_514: "",
    easygoi_515: "",
    campoe_18_517: "",
    campoe_40_518: "",
    campoe_42_520: "",
    //checkboxs
    campoe_23_519: '',
    campoe_72_522:'',
    campoe_72_522_yes:'',
    campoe_72_522_no:'',
  },
  constraints: {
    fname_512: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 3,
        tooShort: "^Digite seu nome corretamente",
      },
    },
    lname_513: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 3,
        tooShort: "^Digite seu apelido corretamente",
      },
    },
    email_516: {
      presence: { message: "Campo obrigatório" },
      email: { message: "^Digite um email válido" },
    },
    ind_telemovel_511: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 2,
        tooShort: "^Digite seu nome corretamente",
      },
    },
    telemovel_511_numero: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 5,
        tooShort:  "^Digite um numero de telemovél válido",
      },
    },
    easygoi_514: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite seu cargo",
      },
    },
    easygoi_515: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_18_517: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_40_518: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_42_520: {
      presence: { message: "Campo obrigatório" },
      length: {
        minimum: 1,
        tooShort: "^Digite o nome da sua empresa cargo",
      },
    },
    campoe_23_519: {
      presence: {
        message: "^Campo obrigatório"
      },
      inclusion: {
        within: [true],
        message: "^Para proceder deve aceitar todos os termos da Política de privacidade IDC"
      }
    },
    campoe_72_522: {
      presence: {
        message: "^Escolha uma opção"
      },
      inclusion: {
        within: [true],
        message: "^Para proceder deve aceitar todos os termos da Política de privacidade IDC"
      }
    },
  },
  etapas: [
    {
      itens: [
        {
          item:
            "Portáteis profissionais Elitebook x360 1030 G4 e Elitebook x360 1040 G6",
          url:
            "https://www8.hp.com/pt/pt/elite-family/elitebook-x360-series/index.html",
        },
        {
          item: "Portátil profissional Elite X2 G4",
          url: "https://www8.hp.com/pt/pt/elite-family/elite-x2.html",
        },
      ],
    },
    {
      itens: [
        {
          item:
            "Óculos de realidade virtual Reverb",
          url:
            "https://www8.hp.com/pt/pt/campaigns/vrready-workstations/index.html",
        },
      ],
    },
    {
      itens: [
        {
          item:
            "Workstation Zbook Studio",
          url:
            "https://www8.hp.com/pt/pt/campaigns/zbook-studio/overview.html",
        },
      ],
    },
    {
      itens: [
        {
          item:
            "Impressora laser HP NeverStop",
          url:
            "https://www8.hp.com/pt/pt/printers/neverstop-laser.html",
        },
        {
          item:
            "Impressora HP Pagewide 779dn",
          url:
            "https://www8.hp.com/pt/pt/printers/pagewide-business-printers.html",
        },
      ],
    },
    { 
      itens: [
        {
          item:
            "Monitores profissionais Z878c curved",
          url:
            "https://www8.hp.com/pt/pt/workstations/z-displays-family/index.html",
        },
      ],
    },
  ],
};
