import React from "react";
import styles from "./home-solucoes.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import Image from "./assets/home-solucoes.png";
import ModalImage from "./home-solucoes-modal";

function HomeServicos() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Session className={styles.session}>
         <SessionBody className={styles.session__img}>
          <img src={Image} />
        </SessionBody>
      </Session>
      <ModalImage open={modalIsOpen} onCloseModal={onCloseModal} />
    </div>
  );
}

export default HomeServicos;
