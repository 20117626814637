import React from "react";
import styles from "./home-workplace.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import { Fade } from "react-reveal";
function HomeHeader() {
  return (
    

    
      <Session className={styles.session}>
        <Fade left duration={1000} distance={'2%'}>
        <SessionBody className={styles.session__body}>
          <h1>5 Etapas para o Futuro do Workplace</h1>
        </SessionBody>
        </Fade>
      </Session>
 
  );
}

export default HomeHeader;
