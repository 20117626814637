import React from "react";
import styles from "./home-video.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import Image from "./assets/exemplo-video.png";
function HomeServicos() {
  return (
    <div id='video'>
      <Session className={styles.session}>
        <SessionBody className={styles.session__img}>
          <img src={Image}/>
                 </SessionBody>
      </Session>
    </div>
  );
}

export default HomeServicos;
