import React from "react";
import styles from "./home-etapas.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";
import Etapa1 from "./assets/etapa-1.png";
import Etapa2 from "./assets/etapa-2.png";
import Etapa3 from "./assets/etapa-3.png";
import Etapa4 from "./assets/etapa-4.png";
import Etapa5 from "./assets/etapa-5.png";
import logoHP from "../../assets/logomarca-hp.png";
import Fade from "react-reveal/Fade";
import config from "./config";
function useHover() {
  const [value, setValue] = React.useState(false);
  const [etapas, setEtapas] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const ref = React.useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  React.useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [] // Recall only if ref changes
  );
  return [ref, value];
}
function HomeHeader() {
  const duration = 1000;
  const distance = "1%";
  const [etapas, setEtapas] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const showEtapa = () => {};

  return (
    <div>
      <Session className={styles.session}>
        <SessionBody className={styles.session__body}>
          <Fade duration={duration} distance={distance} left>
            <div className={styles.etapa}>
              <div className={styles.etapa__img}>
                <img
                  src={Etapa1}
                  alt="Análise do contexto e definição de uma visão"
                />
              </div>
              <div className={styles.etapa__text}>
                <h2>Etapa 1</h2>
                <h1>Análise do contexto e definição de uma visão </h1>
                <p>
                  A IDC prevê que, até 2023, 30% das Empresas do Global 2000
                  irão gerar pelo menos 20% das suas receitas fora das suas
                  principais indústrias, usando modelos ágeis e de crowdsourcing
                  para obter talentos e recursos de negócio.
                </p>
                <div className={styles.etapa__button}>
                  <div>
                    <a href="#white-paper">
                      <div className={styles.button}>download</div>
                    </a>
                  </div>
                  <div
                    className={styles.buttons__hp}
                    onMouseEnter={() =>
                      setEtapas([!etapas[0], false, false, false, false])
                    }
                    onMouseLeave={() =>
                      setEtapas([false, false, false, false, false])
                    }
                  >
                    {etapas[0] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[0]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[0].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>

                  <div
                    className={styles.buttons__hpMobile}
                    onClick={() =>
                      setEtapas([true, false, false, false, false])
                    }
                  >
                    {etapas[0] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[0]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[0].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade duration={duration} distance={distance} right>
            <div className={styles.etapaRight}>
              <div className={styles.etapaRight__img}>
                <img src={Etapa2} alt="Viabilizar a estratégia" />
              </div>
              <div className={styles.etapaRight__text}>
                <h2>Etapa 2</h2>
                <h1>Viabilizar a estratégia </h1>
                <p>
                  De acordo com o modelo de maturidade do Futuro do Trabalho da
                  IDC, a avaliação do Futuro do Workplace deverá considerar
                  temas como: Locais de trabalho; Produtividade e Colaboração;
                  Mobilidade e Experiências Imersivas; Segurança e Confiança.
                </p>
                <div className={styles.etapa__button}>
                  <div
                    className={styles.buttons__hp}
                    onMouseEnter={() =>
                      setEtapas([false, !etapas[1], false, false, false])
                    }
                    onMouseLeave={() =>
                      setEtapas([false, false, false, false, false])
                    }
                  >
                    {etapas[1] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[1]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[1].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.buttons__hpMobile}
                    onClick={() =>
                      setEtapas([false, true, false, false, false])
                    }
                  >
                    {etapas[1] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[1]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[1].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                  <div>
                    <a href="#white-paper">
                      <div className={styles.button}>download</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade duration={duration} distance={distance} left>
            <div className={styles.etapa}>
              <div className={styles.etapa__img}>
                <img src={Etapa3} alt="Explorar vantagens competitivas" />
              </div>
              <div className={styles.etapa__text}>
                <h2>Etapa 3</h2>
                <h1>Explorar vantagens competitivas </h1>
                <p>
                  Ao explorar as vantagens competitivas no WorkpLace, lembre-se
                  das seguintes perguntas-chave: Qual poderá ser a nossa
                  vantagem competitiva quando todos os concorrentes tiverem
                  acesso às mesmas tecnologias e recursos (como é normal num
                  contexto de tecnologias as-a-service)?
                </p>
                <div className={styles.etapa__button}>
                  <div>
                    <a href="#white-paper">
                      <div className={styles.button}>download</div>
                    </a>
                  </div>
                  <div
                    className={styles.buttons__hp}
                    onMouseEnter={() =>
                      setEtapas([false, false, !etapas[2], false, false])
                    }
                    onMouseLeave={() =>
                      setEtapas([false, false, false, false, false])
                    }
                  >
                    {etapas[2] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[2]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[2].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.buttons__hpMobile}
                    onClick={() =>
                      setEtapas([false, false, true, false, false])
                    }
                  >
                    {etapas[2] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[2]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[2].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade duration={duration} distance={distance} right>
            <div className={styles.etapaRight}>
              <div className={styles.etapaRight__img}>
                <img
                  src={Etapa4}
                  alt="Desenvolver o roadmap para o Futuro do Workplace "
                />
              </div>
              <div className={styles.etapaRight__text}>
                <h2>Etapa 4</h2>
                <h1>Desenvolver o roadmap para o Futuro do Workplace </h1>
                <p>
                  Desenvolver o roadmap para o Futuro do Workplace Ao definir o
                  roadmap, lembre-se das seguintes perguntas-chave: Como é que a
                  nossa Organização irá alcançar a Futuro do Workplace - é
                  sozinha ou com parceiros? Quais serão os parceiros chave?
                </p>
                <div className={styles.etapa__button}>
                  <div
                    className={styles.buttons__hp}
                    onMouseEnter={() =>
                      setEtapas([false, false, false, !etapas[3], false])
                    }
                    onMouseLeave={() =>
                      setEtapas([false, false, false, false, false])
                    }
                  >
                    {etapas[3] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[3]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[3].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                  <div>
                    <a href="#white-paper">
                      <div className={styles.button}>download</div>
                    </a>
                  </div>
                </div>

                <div
                    className={styles.buttons__hpMobile}
                    onClick={() =>
                      setEtapas([false, false, false, true, false])
                    }
                  >
                    {etapas[3] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[3]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[3].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>

              </div>
            </div>
          </Fade>
          <Fade duration={duration} distance={distance} left>
            <div className={styles.etapa}>
              <div className={styles.etapa__img}>
                <img
                  src={Etapa5}
                  alt="Análise do contexto e definição de uma visão"
                />
              </div>
              <div className={styles.etapa__text}>
                <h2>Etapa 5</h2>
                <h1>Estratégia de implementação </h1>
                <p>
                  Para melhorar as experiências e aumentar as capacidades da sua
                  Força de Trabalho, as Organizações precisam de explorar as
                  formas como a tecnologia pode ajudar a melhorar os processos e
                  as aplicações de negócios (através do uso da inteligência
                  incorporada).
                </p>
                <div className={styles.etapa__button}>
                  <div>
                    <a href="#white-paper">
                      <div className={styles.button}>download</div>
                    </a>
                  </div>
                  <div
                    className={styles.buttons__hp}
                    onMouseEnter={() =>
                      setEtapas([false, false, false, false, !etapas[4]])
                    }
                    onMouseLeave={() =>
                      setEtapas([false, false, false, false, false])
                    }
                  >
                    {etapas[4] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[4]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[4].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>

                  <div
                    className={styles.buttons__hpMobile}
                    onClick={() =>
                      setEtapas([false, false, false, false, true])
                    }
                  >
                    {etapas[4] ? (
                      <Fade
                        duration={duration}
                        distance={"1%"}
                        top
                        when={etapas[4]}
                      >
                        <div className={styles.button__hp__item}>
                          <img src={logoHP} alt="" />
                          <div>
                            {config.etapas[4].itens.map((item) => (
                              <div>
                                <a target="_black" href={item.url}>
                                  > {item.item}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <div className={styles.button__hp}>
                        <span>SOLUÇÕES</span>
                        <img src={logoHP} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </SessionBody>
      </Session>
    </div>
  );
}

export default HomeHeader;
