import React from "react";
import styles from "./home.module.scss";
import HomeHeader from './home-header'
import HomeServices from './home-solucoes'
import HomeForm from './home-form'
import HomeVideo from './home-video'
import HomeWorkplace from './home-workplace'
import HomeEtapas from './home-etapas'
function Home() {
  return (
    <div>
  <HomeHeader/>
  <HomeServices/>
  <HomeWorkplace/>
  <HomeEtapas/>
  <HomeForm/>
    </div>
  );
}
 /**
   
   
   */ 
export default Home;
