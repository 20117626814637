import React from "react";
import styles from "./footer.module.scss";
import cn from "classnames";
import logomarca from "../../assets/logomarca.png";
import logomarcaImg from "../../assets/logomarca-hp.png";
export const Footer = ({ className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.wrapper, className)}>
        <div>
          <div className={cn(styles.container__copyright, className)}>
            Copyright 2020 © IDC ANALYZE THE FUTURE
          </div>
        </div>
        <div>
          <div className={cn(styles.container__images, className)}>
            <img src={logomarca}></img>
            <img src={logomarcaImg}></img>
          </div>
        </div>
      </div>
    </div>
  );
};
