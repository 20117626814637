import React from "react";
import styles from "./home-header.module.scss";
import { Session, SessionBody, SessionHeader } from "../../modules/session";

function HomeHeader() {
  return (
    <div>
      <Session className={styles.session}>
        <SessionBody className={styles.session__body}>
          <h2>Roadmap</h2>
          <h1>Para o Futuro do Workplace </h1>
        </SessionBody>
      </Session>
      <Session className={styles.box}>
        <SessionBody className={styles.box__box}>
         <div>
         A IDC prevê que até 2024, 30% das Empresas do G2000 terão um ecossistema de Trabalho global, seguro, inteligente, altamente integrado e colaborativo que permitirá que as Empresas funcionem como Organizações sem fronteiras.  
         </div>
        </SessionBody>
      </Session>
    </div>
  );
}

export default HomeHeader;
